import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import tableClientLoginMissionsReducer from 'redux/table/clientLoginMissions/clientLoginMissions.slice';
import userReducer from 'redux/user/user.slice';
import notificationReducer from 'redux/notification/notification.slice';
import projectMissionReducer from 'redux/collections/projectMission/projectMission.slice';
import collectionContactsReducer from 'redux/collections/contacts/contacts.slice';
import collectionPayslipsReducer from 'redux/collections/payslips/payslips.slice';
import collectionInvoicesReducer from 'redux/collections/invoices/invoices.slice';
import collectionExpensesReducer from 'redux/collections/expenses/expenses.slice';
import collectionTimesheetsReducer from 'redux/collections/timesheets/timesheets.slice';
import collectionContactDocumentsReducer from 'redux/collections/contactDocuments/contactDocuments.slice';
import collectionConversationsReducer from 'redux/collections/conversations/conversations.slice';
import projectShiftReducer from 'redux/collections/projectShifts/projectShifts.slice';
import calendarReducer from 'redux/calendar/calendar.slice';
import messagesReducer from 'redux/messages/messages.slice';
import tableProjectShiftsReducer from 'redux/table/projectShifts/projectShifts.slice';
import tableTimetrackingsReducer from 'redux/table/timetrackings/timetrackings.slice';
import tableRatingsReducer from 'redux/table/ratings/ratings.slice';
import tableMissionCompanyDocumentsReducer from 'redux/table/missionCompanyDocuments/missionCompanyDocuments.slice';
import tableCompanyDocumentsReducer from 'redux/table/companyDocuments/companyDocuments.slice';
import tableJobAdsReducer from 'redux/table/jobAds/jobAds.slice';
import searchReducer from 'redux/search/search.slice';
import { teamHeroApi } from 'services/teamHeroApi.service';
import { publicTeamHeroApi } from 'services/publicTeamHeroApi.service';

export const store = configureStore({
  reducer: {
    user: userReducer,
    notification: notificationReducer,
    projectMission: projectMissionReducer,
    projectShift: projectShiftReducer,
    collectionContacts: collectionContactsReducer,
    collectionPayslips: collectionPayslipsReducer,
    collectionExpenses: collectionExpensesReducer,
    collectionInvoices: collectionInvoicesReducer,
    collectionTimesheets: collectionTimesheetsReducer,
    collectionContactDocuments: collectionContactDocumentsReducer,
    collectionConversations: collectionConversationsReducer,
    calendar: calendarReducer,
    messages: messagesReducer,
    tableClientLoginMissions: tableClientLoginMissionsReducer,
    tableProjectShifts: tableProjectShiftsReducer,
    tableTimetrackings: tableTimetrackingsReducer,
    tableRatings: tableRatingsReducer,
    tableMissionCompanyDocuments: tableMissionCompanyDocumentsReducer,
    tableCompanyDocuments: tableCompanyDocumentsReducer,
    tableJobAds: tableJobAdsReducer,
    search: searchReducer,
    [teamHeroApi.reducerPath]: teamHeroApi.reducer,
    [publicTeamHeroApi.reducerPath]: publicTeamHeroApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(teamHeroApi.middleware)
      .concat(publicTeamHeroApi.middleware),
});

setupListeners(store.dispatch);

export type TRootState = ReturnType<typeof store.getState>;
export type TAppDispatch = typeof store.dispatch;
