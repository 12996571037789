import { FC, lazy } from 'react';
import { Routes, Route } from 'react-router';
import * as Sentry from '@sentry/react';

import { useCurrentUser } from 'hooks/useCurrentUser';
import RequireAuth from './RequireAuth';
import checkAuth from './checkAuth';
import { requiredUserRolesConfig } from 'config/userRoles/userRoles.config';
import Logout from 'pages/Logout';
import ClientLoginMissionDetails from 'pages/ClientLoginMissionDetails';
import RetireLoginLayout from 'components/RetireLoginLayout';
import RetiredLoginDashboard from 'components/RetireLoginDashboard';
import NoPermission from 'pages/NoPermission';

const ClientLoginLayout = lazy(() => import('components/ClientLoginLayout'));
const ClientLoginDashboard = lazy(() => import('pages/ClientLoginDashboard'));
const ClientLoginWork = lazy(() => import('pages/ClientLoginWork'));
const ApplicationLayout = lazy(() => import('components/ApplicationLayout'));
const Layout = lazy(() => import('components/Layout'));
const ApplicationDashboard = lazy(() => import('pages/ApplicationDashboard'));
const JobAdApplyForm = lazy(() => import('pages/public/JobAdApplyForm'));
const JobAdLandingPage = lazy(() => import('pages/public/JobAdLandingPage'));
const NotFound = lazy(() => import('pages/NotFound'));
const Login = lazy(() => import('pages/Login'));
const ChangePassword = lazy(() => import('pages/ChangePassword'));
const OutdatedPasswordUpdate = lazy(
  () => import('pages/OutdatedPasswordUpdate')
);
const ChangeEmail = lazy(() => import('pages/ChangeEmail'));
const ChangeEmailConfirm = lazy(() => import('pages/ChangeEmailConfirm'));
const PasswordForgot = lazy(() => import('pages/PasswordForgot'));
const Dashboard = lazy(() => import('pages/Dashboard'));
const Messages = lazy(() => import('pages/Messages'));
const Profile = lazy(() => import('pages/Profile'));
const ProfileCalendar = lazy(() => import('pages/ProfileCalendar'));
const Work = lazy(() => import('pages/Work'));
const LoginViaMagicLink = lazy(() => import('pages/LoginViaMagicLink'));
const Notifications = lazy(() => import('pages/Notifications'));
const Gallery = lazy(() => import('pages/Gallery'));
const LiveMode = lazy(() => import('pages/LiveMode'));

const RoutesComponent: FC = () => {
  const user = useCurrentUser();
  const SentryRoutes = Sentry.withSentryReactRouterV7Routing(Routes);

  return (
    <SentryRoutes>
      {/* Public routes */}
      <Route path='/login' element={<Login />} />
      <Route path='/logout' element={<Logout />} />
      <Route path='/password-forgot' element={<PasswordForgot />} />
      <Route path='/linklogin' element={<LoginViaMagicLink />} />
      <Route path='/loginlink' element={<LoginViaMagicLink />} />
      {/* Change Password Route */}
      <Route
        element={
          <RequireAuth
            requiredRoles={requiredUserRolesConfig.changePassword}
            redirectPath='/logout?silent=true'
          >
            <ChangePassword />
          </RequireAuth>
        }
        path='/change-password'
      />
      <Route
        element={
          <RequireAuth
            requiredRoles={requiredUserRolesConfig.passwordUpdate}
            redirectPath='/logout?silent=true'
          >
            <OutdatedPasswordUpdate />
          </RequireAuth>
        }
        path='/password-update'
      />
      {/* Change Email Route */}
      <Route
        element={
          <RequireAuth
            requiredRoles={requiredUserRolesConfig.changeEmail}
            redirectPath='/logout?silent=true'
          >
            <ChangeEmail />
          </RequireAuth>
        }
        path='/change-email'
      />
      {/* Change email confirm route */}
      <Route
        element={
          <RequireAuth
            requiredRoles={requiredUserRolesConfig.changeEmail}
            redirectPath='/logout?silent=true'
          >
            <ChangeEmailConfirm />
          </RequireAuth>
        }
        path='/confirm-email-change'
      />
      <Route path='/job/:id' element={<JobAdApplyForm />} />
      <Route path='/job' element={<JobAdLandingPage />} />
      <Route path='/job-ad'>
        <Route index element={<JobAdLandingPage />} />
        <Route path='*' element={<JobAdLandingPage />} />
      </Route>
      <Route path='/gallery/:id' element={<Gallery />} />
      {/* RETIRED routes */}
      {checkAuth({
        user,
        requiredRoles: requiredUserRolesConfig.retiredLogin,
      }) && (
        <Route element={<RetireLoginLayout />}>
          <Route path='/' element={<RetiredLoginDashboard />} />
          <Route path='/messages'>
            <Route index element={<Messages />} />
            <Route
              path='new-message'
              element={<Messages conversationType={'new-message'} />}
            />
            <Route
              path='new-conversation'
              element={<Messages conversationType={'new-conversation'} />}
            />
            <Route
              path=':id/details'
              element={<Messages conversationType={'existing-conversation'} />}
            />
          </Route>

          <Route path='/work'>
            <Route index path='*' element={<NoPermission />} />
          </Route>

          <Route path='/notifications' element={<Notifications />} />
          <Route path='/profile' element={<Profile />} />
          <Route path='/profile-calendar' element={<ProfileCalendar />} />
          {/* 404 - catch all route */}
          <Route path='*' element={<NotFound />} />
        </Route>
      )}
      {/* Application routes */}
      {checkAuth({
        user,
        requiredRoles: requiredUserRolesConfig.application,
      }) && (
        <Route element={<ApplicationLayout />}>
          <Route path='/' element={<ApplicationDashboard />} />
          <Route path='*' element={<NotFound />} />
        </Route>
      )}
      {/* Client Login routes */}
      {checkAuth({
        user,
        requiredRoles: requiredUserRolesConfig.clientLogin,
      }) && (
        <Route element={<ClientLoginLayout />}>
          <Route path='/' element={<ClientLoginDashboard />} />
          <Route path='/messages'>
            <Route index element={<Messages />} />
            <Route
              path='new-message'
              element={<Messages conversationType={'new-message'} />}
            />
            <Route
              path='new-conversation'
              element={<Messages conversationType={'new-conversation'} />}
            />
            <Route
              path=':id/details'
              element={<Messages conversationType={'existing-conversation'} />}
            />
          </Route>
          <Route path='/work'>
            <Route index element={<ClientLoginWork />} />
            <Route path=':id/details' element={<ClientLoginMissionDetails />} />
          </Route>
          {/* 404 - catch all route */}
          <Route path='*' element={<NotFound />} />
        </Route>
      )}
      {/* HEROLOGIN routes */}
      <Route
        element={
          <RequireAuth
            requiredRoles={requiredUserRolesConfig.heroLogin}
            redirectPath='/logout?silent=true'
          >
            <Layout />
          </RequireAuth>
        }
      >
        <Route path='/' element={<Dashboard />} />
        <Route path='/messages'>
          <Route index element={<Messages />} />
          <Route
            path='new-message'
            element={<Messages conversationType={'new-message'} />}
          />
          <Route
            path='new-conversation'
            element={<Messages conversationType={'new-conversation'} />}
          />
          <Route
            path=':id/details'
            element={<Messages conversationType={'existing-conversation'} />}
          />
        </Route>
        <Route path='/profile' element={<Profile />} />
        <Route path='/work' element={<Work />} />
        <Route path='/profile-calendar' element={<ProfileCalendar />} />
        <Route path='/notifications' element={<Notifications />} />
        <Route path=':projectId/livemode' element={<LiveMode />} />
        {/* 404 - catch all route */}
        <Route path='*' element={<NotFound />} />
      </Route>
    </SentryRoutes>
  );
};

export default RoutesComponent;
